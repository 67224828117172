// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import TaskService from "../services/TaskService";
// import UserService from "../services/UserService";

// const Checklist = () => {
//     const [tasks, setTasks] = useState([]); // Renamed from parentTasks
//     const [loading, setLoading] = useState(true); // Renamed from isLoading
//     const [university, setUniversity] = useState(null); // Renamed from selectedUniversity
//     const [userData, setUserData] = useState(null); // Renamed from user
//     const navigate = useNavigate();

//     // Fetching tasks from the service
//     useEffect(() => {
//         const taskService = new TaskService();
//         const loadTasks = async () => {
//             try {
//                 const fetchedTasks = await taskService.getParentTasks();
//                 setTasks(fetchedTasks || []);
//             } catch (error) {
//                 console.error("Error fetching tasks:", error);
//                 setTasks([]);
//             } finally {
//                 setLoading(false);
//             }
//         };
//         loadTasks();
//     }, [university]);

//     // Fetching user and university data
//     useEffect(() => {
//         const loadUserDetails = async () => {
//             try {
//                 const currentUser = await UserService.getProfileData();
//                 setUserData(currentUser);

//                 const userUniversities = await UserService.getUserUniversities();
//                 setUniversity(userUniversities[0]);
//             } catch (error) {
//                 console.error("Error fetching user or university data:", error);
//             }
//         };
//         loadUserDetails();
//     }, []);

//     // Display loading indicator while fetching data
//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     // Calculate task completion percentage
//     const completedTaskCount = tasks.filter((task) => task.completed).length;
//     const totalTaskCount = tasks.length;
//     const completionPercentage = totalTaskCount > 0 ? Math.round((completedTaskCount / totalTaskCount) * 100) : 0;

//     return (
//         <div className="w-full px-4 mt-24">
//             {/* Title Section */}
//             <div className="bg-gradient-to-r from-blue-50 via-white to-blue-50 text-center py-20">
//                 <h1 className="text-5xl md:text-6xl font-extrabold text-gray-900 leading-tight">
//                     Track Your <span className="text-blue-600">Progress</span> <br className="hidden md:inline-block" />
//                     & Crush Your <span className="text-blue-600">Checklist</span>
//                 </h1>
//                 <p className="text-xl md:text-2xl text-gray-500 mt-6 max-w-xl mx-auto">
//                     Make every step count on your journey to success with seamless tracking and task management.
//                 </p>
//             </div>

//             {/* User Profile Section */}
//             {userData && (
//                 <div className="flex flex-col items-center justify-center w-full py-20">
//                     <div className="flex items-center">
//                         <div className="avatar bg-gray-200 rounded-full flex items-center justify-center w-12 h-12">
//                             <img
//                                 src={userData.photoURL || "https://via.placeholder.com/50"}
//                                 alt="User Avatar"
//                                 className="rounded-full w-full h-full"
//                             />
//                         </div>
//                         <div className="ml-4">
//                             <h2 className="text-lg font-semibold">{userData.displayName}</h2>
//                             <p className="text-sm text-gray-600">
//                             Student at {university?.collegeName}

//                             </p>
//                         </div>
//                     </div>

//                     {/* Progress Bar */}
//                     <div className="w-full bg-gray-300 rounded-full h-2 mt-4">
//                         <div
//                             className="bg-blue-600 h-2 rounded-full transition-all duration-500 ease-in-out"
//                             style={{ width: `${completionPercentage}%` }}
//                         ></div>
//                     </div>
//                     <p className="text-blue-600 font-semibold mt-2">{completionPercentage}% Complete</p>
//                 </div>
//             )}

//             {/* Tasks Section */}
//             <div className="w-full text-center mx-auto">
//                 <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 w-full">
//                     {tasks.map((task) => (
//                         <div
//                             key={task.taskId}
//                             className={`relative bg-white p-6 shadow-lg rounded-lg flex flex-col items-center transition transform hover:scale-105 min-h-[300px] min-w-[200px] ${
//                                 task.completed ? "opacity-50" : ""
//                             }`}
//                             onClick={() =>
//                                 navigate(`/task/${task.taskId}`, {
//                                     state: { taskId: task.taskId, taskName: task.taskName, videoUrl: task.assetUrl },
//                                 })
//                             }
//                             style={{ cursor: "pointer" }}
//                         >
//                             <div className="task-icon mb-3 w-full h-60 flex items-center justify-center">
//                                 <img
//                                     src={task.iconUrl || "https://via.placeholder.com/300"}
//                                     alt={task.taskName}
//                                     className="w-full h-full object-contain"
//                                 />
//                             </div>
//                             <h4 className="text-lg font-semibold text-blue-600 text-center">{task.taskName}</h4>

//                             {/* Mark Complete / Undo Button */}
//                             <div className="mt-auto">
//                                 <button
//                                     onClick={(e) => {
//                                         e.stopPropagation();
//                                         setTasks((prevTasks) =>
//                                             prevTasks.map((t) =>
//                                                 t.taskId === task.taskId ? { ...t, completed: !t.completed } : t
//                                             )
//                                         );
//                                     }}
//                                     className={`mt-4 py-2 px-4 rounded-lg text-white transition ${
//                                         task.completed
//                                             ? "bg-red-500 hover:bg-red-600"
//                                             : "bg-green-500 hover:bg-green-600"
//                                     }`}
//                                 >
//                                     {task.completed ? "Undo" : "Mark Complete"}
//                                 </button>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Checklist;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TaskService from "../services/TaskService";
import UserService from "../services/UserService";
import { FaCheckCircle } from "react-icons/fa";
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth, googleProvider } from '../config/FirebaseConfig';
import dummyImg from '../assets/pre4Usa.png';
import loaderGif from '../assets/loader.gif';
const Checklist = () => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [university, setUniversity] = useState(null);
    const [userData, setUserData] = useState(null);
    const [showLoginPrompt, setShowLoginPrompt] = useState(false);
    const navigate = useNavigate();

    // Fetch tasks on component mount, regardless of login status
    useEffect(() => {
        const loadTasks = async () => {
            try {
                const taskService = new TaskService();
                const fetchedTasks = await taskService.getParentTasks();
                setTasks(fetchedTasks || []);
            } catch (error) {
                console.error("Error fetching tasks:", error);
                setTasks([]);
            } finally {
                setLoading(false);
            }
        };

        const initializeUser = async () => {
            const loggedIn = await UserService.isUserLoggedIn();
            if (loggedIn) {
                try {
                    const currentUser = await UserService.getProfileData();
                    setUserData(currentUser);
                    const userUniversities = await UserService.getUserUniversities();
                    setUniversity(userUniversities[0]);
                } catch (error) {
                    console.error("Error fetching user or university data:", error);
                }
            }
        };

        loadTasks();
        initializeUser();
    }, []);

    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.idToken;

            const loggedInUser = {
                userId: result.user.uid,
                displayName: result.user.displayName,
                email: result.user.email,
                photoURL: result.user.photoURL,
            };

            await UserService.signInWithGoogle(token, loggedInUser);
            setUserData(loggedInUser);
            localStorage.setItem('user', JSON.stringify(loggedInUser));

            // Redirect to profile page after successful login
            navigate('/profile');
        } catch (error) {
            console.error('Google Sign-In Error:', error);
        }
    };

    // Calculate task completion percentage for logged-in users
    const completedTaskCount = tasks.filter((task) => task.completed).length;
    const totalTaskCount = tasks.length;
    const completionPercentage = totalTaskCount > 0 ? Math.round((completedTaskCount / totalTaskCount) * 100) : 0;

    if (loading) {
        return <div className='loader'><span><img src={loaderGif} alt="Loader"/></span></div>;
    }

    const renderUserInfo = () => (
        <div className="bg-white p-4 shadow-md rounded-lg flex items-center space-x-4 mb-4 userProfileBox">
            <img
                src={userData.photoURL || dummyImg}
                alt="User Avatar"
                className="w-12 h-12 rounded-full"
            />
            <div>
                <p className="text-lg font-semibold">Hello, {userData.displayName} 👋</p>
                <p className="text-sm text-gray-600">{university?.collegeName || "University Name"}</p>
            </div>
        </div>
    );

    const renderLoginBanner = () => (
        <div className="bg-white p-4 shadow-md rounded-lg text-center mb-4">
            <p className="text-lg font-semibold">
                To track your progress and chat with peers, please log in and verify your university.
            </p>
            <button className="mt-4 py-2 px-6 bg-blue-500 text-white rounded-md font-semibold shadow-md" onClick={signInWithGoogle}>
                Login with Google
            </button>
        </div>
    );

    return (
        <div className="w-full px-4 mt-16 space-y-4">
            {/* Show user info if logged in, else show login banner */}
            {userData ? renderUserInfo() : renderLoginBanner()}

            {/* Progress Bar - Only visible for logged-in users */}
            {userData && (
                <>
                    <div className="flex items-center space-x-2 text-gray-500 profileUser">
                        <p>Your progress of all the tasks below</p>
                        <p className="text-orange-500 font-bold">{completionPercentage}%</p>
                    </div>
                    <div className="w-full bg-gray-300 rounded-full h-2 mb-4 chcklistProgess">
                        <div
                            className="bg-blue-600 h-2 rounded-full transition-all duration-500 ease-in-out"
                            style={{ width: `${completionPercentage}%` }}
                        ></div>
                    </div>
                </>
            )}

            {/* Tasks Section */}
            <div className="grid grid-cols-1 gap-4">
                {tasks.map((task) => (
                    <div
                        key={task.taskId}
                        className="relative bg-white p-4 shadow-md rounded-lg border border-gray-200 flex flex-col"
                        onClick={() =>
                            navigate(`/task/${task.taskId}`, {
                                state: { taskId: task.taskId, taskName: task.taskName, videoUrl: task.assetUrl },
                            })
                        }
                    >
                        <div className="flex items-center space-x-4">
                            <img
                                src={task.iconUrl || dummyImg}
                                alt={task.taskName}
                                className="w-24 h-24 object-cover rounded-md"
                            />
                            <div>
                                <h4 className="text-md font-semibold text-gray-700">{task.taskName}</h4>
                                <p className="text-gray-500 text-sm">Status: <span className={task.completed ? "statusBox completed" : "statusBox pending"}>{task.completed ? "Completed" : "Pending"}</span></p>
                            </div>
                        </div>

                        {/* Completion Icon */}
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!userData) {
                                    setShowLoginPrompt(true);
                                } else {
                                    setTasks((prevTasks) =>
                                        prevTasks.map((t) =>
                                            t.taskId === task.taskId ? { ...t, completed: !t.completed } : t
                                        )
                                    );
                                }
                            }}
                            className="absolute bottom-4 right-4 cursor-pointer"
                        >
                            <FaCheckCircle
                                size={24}
                                className={`${
                                    task.completed ? "text-green-500" : "text-orange-500"
                                } transition-colors duration-300`}
                            />
                        </div>
                    </div>
                ))}
            </div>

            {/* Login Prompt Modal - Appears if user tries to mark a task complete without logging in */}
            {showLoginPrompt && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center loginPop">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-xs">
                        <p className="text-lg font-semibold mb-4">Please log in to mark tasks as complete</p>
                        <button className="py-2 px-6 bg-blue-500 text-white rounded-md font-semibold shadow-md w-full" onClick={signInWithGoogle}>
                        Login with Google
                        </button>
                        <button
                            onClick={() => setShowLoginPrompt(false)}
                            className="mt-4 text-sm text-gray-500"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Checklist;

