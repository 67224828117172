import React from 'react';
import { useNavigate } from 'react-router-dom';
import hero from '../assets/promisebanner.png';

const AboutUs = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/'); // Navigate to the checklist page
  };

  return (
    <section className="overflow-hidden bg-[#DAEFFF] min-h-screen flex flex-col items-center aboutHeader">
      <div className="px-6 py-32 mx-auto sm:px-10 lg:px-20 max-w-3xl lg:max-w-5xl">
        <div className="flex flex-col items-center text-center md:text-left md:items-start space-y-8">
          
          {/* Heading with Extra Spacing */}
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold tracking-tight text-gray-800 mb-8">
            Connect Abroad is built by students, for the students!
          </h1>

          {/* First Paragraph */}
          <p className="text-sm md:text-base text-gray-700 font-medium lg:text-base mb-10">
            We’re a student-led community offering guidance, resources, and support for international students heading to the USA. Built by students who’ve been through the process, we’re here to help you after you've secured your university admission.
          </p>

          {/* Image Section */}
          <div className="mt-6 w-full flex justify-center">
            <img
              className="lg:w-1/2 object-cover object-center shadow-xl rounded-lg"
              alt="hero"
              src={hero}
            />
          </div>

          {/* Centered Call to Action Button Below Image */}
          <div className="flex justify-center mt-8 w-full">
            <button
              onClick={handleButtonClick}
              className="inline-block px-5 py-4 text-white font-semibold tracking-tight bg-primary hover:bg-primary-hover rounded-lg focus:ring-4 focus:ring-primary transition duration-200"
            >
              Begin Your Journey to USA
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
